import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Hello TuringTrader Member,`}</p>
    <p>{`As much as we hoped for September to bring a little calm to investing - unfortunately, things turned out differently. Let us have a closer look:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.turingtrader.com/dashboard/indicators/#bonds?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-october-2023"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3aBR/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQESGB/9oACAEBAAE/IUkjDpU//9oADAMBAAIAAwAAABDgD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAAMAAgMAAAAAAAAAAAAAAAABESExUXGB/9oACAEBAAE/EKjry7s9D7EcCSElD//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "indicators bond market",
            "title": "indicators bond market",
            "src": "/static/5d1f3c008470bd0772711d81a9adb167/e5166/indicators_bond-market.jpg",
            "srcSet": ["/static/5d1f3c008470bd0772711d81a9adb167/f93b5/indicators_bond-market.jpg 300w", "/static/5d1f3c008470bd0772711d81a9adb167/b4294/indicators_bond-market.jpg 600w", "/static/5d1f3c008470bd0772711d81a9adb167/e5166/indicators_bond-market.jpg 1200w", "/static/5d1f3c008470bd0772711d81a9adb167/eea4a/indicators_bond-market.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Since summer, the markets have clearly priced in another rate hike by the Fed. The chart above indicates that, with the 13-week Treasury yield trading significantly above the Federal Funds Rate. But even though the Fed kept rates steady during the `}<a parentName="p" {...{
        "href": "https://www.federalreserve.gov/monetarypolicy/fomccalendars.htm"
      }}>{`September 20 FOMC Meeting`}</a>{`, the markets reacted with a sharp selloff.`}</p>
    <p>{`Two factors contributed here. For once, the Fed still plans another rate hike in 2023, keeping the 13-week yield steady. However, investors have been spooked by the Fed's indication that there will not be more than two rate cuts in 2024. The new reality of keeping higher interest rates for longer sharply pushed up the yields for 10-year and 20-year Treasuries.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.turingtrader.com/dashboard/market-vane/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-october-2023"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "774px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.333333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABDklEQVQY02WOwU7CQBRF+9Gu/AY/wS0GhAokJMbozoULoysToyjQlpYibel0pnSmtRxDA1HiS17y3r25J9fSxmDKkrzQaF2RZppirx28Zne3MeiypFKCOPbwUp9QhITZkriIkUZhFVrDtuZp4nLSHnB2c4s0EmN+gfoAa4CGb5nx5j7T/ryg7/Swp5cMA5uPfIxlqordOFHC6ajH+eMdui6o6+1xy79AlTFdvtB1Olw5XYaezehrwFi9Y6UiY7mKWEURYi2IkzVRnDRavtkcNTR7YC0FfvJK223RmbQYBDYP8p5UC6xd2J37zNw5nh8wDxb4i7D5M6Uoq+o/MFf4yZi+a3PtjJitHFKTIrXkB9s4c/fXIsmwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "tt market vane graphics",
            "title": "tt market vane graphics",
            "src": "/static/b6c3c16773ee3139c6defb66e138142f/41d3b/tt-market-vane_graphics.png",
            "srcSet": ["/static/b6c3c16773ee3139c6defb66e138142f/5a46d/tt-market-vane_graphics.png 300w", "/static/b6c3c16773ee3139c6defb66e138142f/0a47e/tt-market-vane_graphics.png 600w", "/static/b6c3c16773ee3139c6defb66e138142f/41d3b/tt-market-vane_graphics.png 774w"],
            "sizes": "(max-width: 774px) 100vw, 774px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <h2>{`Market Vane: Economic Regime and Stock Market Momentum`}</h2>
    <p>{`Curious investors will have noticed that on 09/22, `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/dashboard/market-vane/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-october-2023"
      }}>{`Market Vane`}</a>{`'s economic indicator suddenly jumped from bearish to decisively bullish. To understand how this is possible, we need to take a closer look at Market Vane's inner mechanics.`}</p>
    <p>{`To gauge the economic regime, Market Vane looks at `}<a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/CCSA"
      }}>{`FRED's Continued Claims series`}</a>{`. It is assumed that bearish regimes are characterized by rising unemployment, while during bullish regimes unemployment is continually declining.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.turingtrader.com/dashboard/market-vane/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-october-2023"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "970px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABRklEQVQoz52TbW/DIAyE+///ZD9s69Suy5YGMAGbZ3IUurR70TRLJwycDsfx7cyMWitzrZRaUdUFfuZ3KSX2+z2Hw4HO7Zwt/NxjF0U4nk6cxsggAVsvtyilLLg/73DBGCMiwq6UCs0YRTnnxH9jnucuWDBTWoOnELBmC6G19itW0jXPOS+ii6CX7PE0CkEzhXolbgW2edGN8CrouBEM2XgchXOORMtXke3aeedJGaISs5FNGWUixXQrqNaYa2MIxkuOKHojZq0RpDFKJZgwqfAcIg9T4C1O5N7DLghtBQyp8lYDhn5WJsaYZ0YLzFa//JQvn/zZo0apjddYeC+Ji6alDafkrZCl0vue3vTQB/a7UIMhGhdpHJNwsfS3sfGNV3k/vLoM7SZfh/y74XZHhRDYdTv56i84tnbqLnBb+sOe/2RBt94HXEewV4x4gz8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "unemployment triggers",
            "title": "unemployment triggers",
            "src": "/static/f870b1efe9ac7dd2c2e21f40c83d237e/587b0/unemployment-triggers.png",
            "srcSet": ["/static/f870b1efe9ac7dd2c2e21f40c83d237e/5a46d/unemployment-triggers.png 300w", "/static/f870b1efe9ac7dd2c2e21f40c83d237e/0a47e/unemployment-triggers.png 600w", "/static/f870b1efe9ac7dd2c2e21f40c83d237e/587b0/unemployment-triggers.png 970w"],
            "sizes": "(max-width: 970px) 100vw, 970px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Coming out of the 2020 COVID peak, unemployment has been declining. Then, in fall 2022, unemployment started to rise, driven by the Fed's activity to fight inflation. However, since spring 2023, unemployment has been declining again. This downward trend is now, with a delay of around six months, picked up by Market Vane.`}</p>
    <p>{`To avoid whipsaws, Market Vane does not attempt to directly measure the slope of the unemployment figures. Instead, Market Vane compares the actual figures to pre-defined trigger points. These trigger points follow the actual numbers with a pre-defined distance, but only in the direction of the currently determined unemployment regime. When the reported unemployment figures cross the trigger level, a new trigger line is established on the opposite side of the trend direction.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.turingtrader.com/dashboard/market-vane/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-october-2023"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3qgB/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFhAAAwAAAAAAAAAAAAAAAAAAASAh/9oACAEBAAE/IRG//9oADAMBAAIAAwAAABATz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAAMAAQUAAAAAAAAAAAAAAAABETEQQXGBkf/aAAgBAQABPxCKaWRPst2MaTn0/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "tt market vane monthly indicators",
            "title": "tt market vane monthly indicators",
            "src": "/static/30c5440a660abf9d797b7fea13e48128/e5166/tt-market-vane_monthly-indicators.jpg",
            "srcSet": ["/static/30c5440a660abf9d797b7fea13e48128/f93b5/tt-market-vane_monthly-indicators.jpg 300w", "/static/30c5440a660abf9d797b7fea13e48128/b4294/tt-market-vane_monthly-indicators.jpg 600w", "/static/30c5440a660abf9d797b7fea13e48128/e5166/tt-market-vane_monthly-indicators.jpg 1200w", "/static/30c5440a660abf9d797b7fea13e48128/eea4a/tt-market-vane_monthly-indicators.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p><a parentName="p" {...{
        "href": "https://www.turingtrader.com/dashboard/market-vane/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-october-2023"
      }}>{`Market Vane`}</a>{` measures the distance between the actual figures and the trigger lines. Consequently, the transition between the regimes is not gradual, but comes with a pronounced jump.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.turingtrader.com/dashboard/market-vane/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-october-2023"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "975px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACdklEQVQoz32S609TQRDF75/MJ40ajTGiIkRFg0HSEkF5KFJAIVAjUaiCMUSxIEaBYksvENtKS7mvff3MbsH4wTjJyZzM7pzMzh5PKYWUCqUkRsOvWp1CocDu7i6lkk+xWML3fVcrl8uUyz6FnSLVahWt9UmvQkiBlBIvimISkWCkoXBYY6m4ikokQiRsVg6I4hghBEpIl0WSUKj4hIlAa1pnShEEAVEU4SVCYCPUsPQjz2T2PtpohIbXG6+IRRNl4CAKAeX4Qn6ete08e9Wy6zUYJxbHMV4cRWAMoYh4+3WWwdk+gmaTrfo+d5/eYs/fQQETuRGOGkc0REzP8B1uD1wkPdmFlokTtZMnSYJXazRcoRnWmcoOkZq4x6a/Tn5rhasP21hYfY7Shp7hTl4sPiKzmOFcXxuXH5+jPXWJ9HQPCxuLVOpVwjDEe782x2GlTNBocGewg+5H7YzNPWbmzROups7QPXCFZ7kMXZkuLjy5QefEPVLZNPO5Kd5+yJIa6iQ92s2njQ9uMO/lUpqbTy8wOjfCtYdnmZ7p58FQD+NzaX5Wi4zNj3F7uIPSYYXjRLv1nIYwhuzyJKVygS9b31qCuZUZOnrP09V/nVx+wRXffJzl+86q43l/jXcfs39ErJz9NGsZbQxf/XX2q0XWt1aJowRvLf+OwcxNBqZ7CcLANa3s/6ByXHP8Z3DMdmXbcStkjHGwobSmIRShFBT2NpFC4n0+/MZAdpTxFyN/pogkzh6tJqjFiv+FvbpXPyJJYrzdZszM8iSz8+OtvVijWteLlvMt1En+F/42toVnlCEWAVKG7sDuxhrUGtXy08s2/w9at17xG3x4aW8+ZkiMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "momentum lookback",
            "title": "momentum lookback",
            "src": "/static/0451a85633f706a9b746b2ab5de3a090/e548f/momentum-lookback.png",
            "srcSet": ["/static/0451a85633f706a9b746b2ab5de3a090/5a46d/momentum-lookback.png 300w", "/static/0451a85633f706a9b746b2ab5de3a090/0a47e/momentum-lookback.png 600w", "/static/0451a85633f706a9b746b2ab5de3a090/e548f/momentum-lookback.png 975w"],
            "sizes": "(max-width: 975px) 100vw, 975px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`At the same time as the economic regime turned bullish, the stock market regime turned flat. In the coming days or weeks, we will see whether it will turn negative, or go back to positive. In any case, the recent period of skittish markets leads Market Vane to significantly reduce its lookback period. As a result, the indicator becomes nimbler, reacting much faster to the market swings. Market Vane achieves this through its built-in walk-forward optimization. `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/articles/market-vane/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-october-2023"
      }}>{`Our background article`}</a>{` explains that in more detail.`}</p>
    <p>{`In summary, even though the economic regime just turned positive, Market Vane is on high alert and ready to exit, should the markets continue to decline.`}</p>
    <div id="maintenance" />
    <h2>{`Site Maintenance October 14-15: What to Expect`}</h2>
    <p>{`The `}<em parentName="p">{`TuringTrader`}</em>{` site is a large software project and undergoes continuous efforts to add content and features, improve member experience, and keep up with technology advancements. Typically, these efforts are seamless, and hardly noticed by our members. However, occasionally, changes may be disruptive. One of these changes is coming up over the weekend of October 14/ 15 when we replace the technology responsible for member authentication and billing. Here is our plan:`}</p>
    <p><strong parentName="p">{` Friday, October 13 `}</strong></p>
    <ul>
      <li parentName="ul">{`The site is operating as usual with portfolio updates posted in the evening.`}</li>
    </ul>
    <p><strong parentName="p">{` Saturday, October 14 `}</strong></p>
    <ul>
      <li parentName="ul">{`Disable membership signup and self-service plan changes.`}</li>
      <li parentName="ul">{`Members can log into their accounts and view their dashboards as usual.`}</li>
    </ul>
    <p><strong parentName="p">{` Sunday, October 15 `}</strong></p>
    <ul>
      <li parentName="ul">{`The new site is online.`}</li>
      <li parentName="ul">{`All user accounts as of 10/14 are migrated, but users need to reset their password to log in.`}</li>
    </ul>
    <p><strong parentName="p">{` Monday, October 16 `}</strong></p>
    <ul>
      <li parentName="ul">{`The new site is fully operational, and we resume signup and self-service plan changes.`}</li>
      <li parentName="ul">{`The old site is available as a fallback in case of technical difficulties.`}</li>
    </ul>
    <p>{`In summary, these are the most important take-aways for our members:`}</p>
    <ul>
      <li parentName="ul">{`The site update should result in `}<strong parentName="li">{`only minor interruptions`}</strong>{`.`}</li>
      <li parentName="ul">{`Your accounts will be migrated, but you will `}<strong parentName="li">{`need to reset your password`}</strong>{`.`}</li>
      <li parentName="ul">{`We have a `}<strong parentName="li">{`fallback in place`}</strong>{`, just in case we hit a roadblock during migration.`}</li>
    </ul>
    <p>{`This new infrastructure will significantly simplify `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/company/sub-processors/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-october-2023"
      }}>{`our technology stack`}</a>{` and allow us to implement site improvements we wanted for years. Our new technology partner will help us consolidate and improve client communication. We are excited about the new possibilities and are eagerly looking forward to the road ahead.`}</p>
    <p>{`As always, I want to thank everybody who reached out over the past month with questions, comments, and suggestions. It is an honor to have you on our site. If you like what we do, support our growth by `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/company/referral-program/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-october-2023"
      }}>{`sharing with your friends`}</a>{` how `}<em parentName="p">{`TuringTrader`}</em>{` adds value for your investing.`}</p>
    <p>{`Stay tuned for further updates,`}<br />{`
All the best`}<br /></p>
    <br />
Felix<br />
 --<br />
Felix Bertram<br />
Founder of TuringTrader.com

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      